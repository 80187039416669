body {
  margin: 0;
  padding: 0;
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
}

#box {
  height: 350px;
  width: 100%;
  background: #96bace;
  transition: background-color 1s, box-shadow .75s;
  position: relative;
  overflow: hidden;
}

#box h1, #box h2 {
  letter-spacing: 1px;
  margin: 0 0 0 100px;
  font-weight: 300;
  transition: color .25s;
}

#box h1 {
  width: 300px;
  margin-top: 80px;
  font-size: 48px;
}

#box h1 strong {
  white-space: nowrap;
  font-weight: 300;
}

#box h2 {
  margin-top: 40px;
  font-size: 32px;
}

#box.evening h1, #box.night h1, #box.evening h2, #box.night h2 {
  color: #fff;
}

#box.morning {
  background-color: #bccfd8;
  box-shadow: inset 0 100px 100px #ffffff40, inset 0 -100px 100px #00000012;
}

#box.afternoon {
  background-color: #8cbdd8;
  box-shadow: inset 0 100px 100px #ffffff26, inset 0 -100px 100px #00000012;
}

#box.evening {
  background-color: #587d91;
  box-shadow: inset 0 -100px 100px #8cbdd840;
}

#box.night {
  background-color: #39525e;
  box-shadow: inset 0 100px 100px #00000026;
}

#timeRange {
  width: 24%;
  margin: 10px 0;
  position: absolute;
  right: 10%;
}

#ninja {
  max-height: 85%;
  max-width: 22%;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 15%;
}

#sun, #moon {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  transition: right .5s, top .5s;
  display: none;
  position: absolute;
}

#sun {
  background: #e9e98a;
  margin-top: -50px;
}

#moon {
  margin-top: -70px;
  margin-left: -20px;
  top: 100%;
  box-shadow: 20px 20px #e9e98a;
}

#main {
  width: 50%;
  color: #666;
  margin: 50px auto;
  font-size: 24px;
}

#main strong, #main a {
  color: #000;
  font-weight: normal;
}

#main a {
  border-bottom: 2px dotted #aaa;
  text-decoration: none;
}

#main a:hover {
  border-bottom-color: #333;
}

#main hr {
  width: 120%;
  border: 1px solid #ddd;
  margin: 40px 0 40px -10%;
}

#main .facts {
  margin: 0;
  padding: 0;
  list-style: none;
}

#main .facts li, #main p {
  margin: 25px 0;
}

@media (max-width: 900px) {
  #box {
    height: 150px;
  }

  #box h1 {
    width: 100%;
    margin: 30px 0 0;
    padding: 0 40% 0 5%;
    font-size: 32px;
  }

  #box h2 {
    width: 100%;
    margin: 20px 0 0;
    padding: 0 40% 0 5%;
    font-size: 24px;
  }

  #sun, #moon {
    height: 50px;
    width: 50px;
  }

  #sun {
    margin-top: -25px;
  }

  #moon {
    margin-top: -35px;
    margin-left: -10px;
    box-shadow: 10px 10px #e9e98a;
  }

  #main {
    width: auto;
    padding: 0 2em;
    font-size: 20px;
  }

  #main hr {
    width: 60%;
    border-bottom: none;
    margin: 30px auto;
  }

  #timeRange {
    width: 90%;
    margin: 10px 5%;
    display: block;
    position: static;
  }
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: 7.9px 0;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.2px;
  cursor: pointer;
  background: #dcdcdc;
  border: 0 solid #dbd5c5;
  border-radius: 25px;
  box-shadow: 0 0 #ffb2df00, 0 0 #ffccea00;
}

input[type="range"]::-webkit-slider-thumb {
  height: 24px;
  width: 24px;
  cursor: pointer;
  -webkit-appearance: none;
  background: #fff;
  border: 5px solid #bbd5ff;
  border-radius: 24px;
  margin-top: -7.9px;
  box-shadow: 4.1px 4.1px 7.2px #78787880, 0 0 4.1px #85858580;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #e1e1e1;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8.2px;
  cursor: pointer;
  background: #dcdcdc;
  border: 0 solid #dbd5c5;
  border-radius: 25px;
  box-shadow: 0 0 #ffb2df00, 0 0 #ffccea00;
}

input[type="range"]::-moz-range-thumb {
  height: 24px;
  width: 24px;
  cursor: pointer;
  background: #fff;
  border: 5px solid #bbd5ff;
  border-radius: 24px;
  box-shadow: 4.1px 4.1px 7.2px #78787880, 0 0 4.1px #85858580;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 8.2px;
  cursor: pointer;
  color: #0000;
  background: none;
  border-color: #0000;
}

input[type="range"]::-ms-fill-lower {
  background: #d7d7d7;
  border: 0 solid #dbd5c5;
  border-radius: 50px;
  box-shadow: 0 0 #ffb2df00, 0 0 #ffccea00;
}

input[type="range"]::-ms-fill-upper {
  background: #dcdcdc;
  border: 0 solid #dbd5c5;
  border-radius: 50px;
  box-shadow: 0 0 #ffb2df00, 0 0 #ffccea00;
}

input[type="range"]::-ms-thumb {
  height: 24px;
  width: 24px;
  cursor: pointer;
  height: 8.2px;
  background: #fff;
  border: 5px solid #bbd5ff;
  border-radius: 24px;
  box-shadow: 4.1px 4.1px 7.2px #78787880, 0 0 4.1px #85858580;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #dcdcdc;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #e1e1e1;
}

/*# sourceMappingURL=index.0c0b5a5a.css.map */
